import React from 'react'

export const Star = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='h-5 w-5'
    viewBox='0 0 20 20'
    width={20}
    height={20}
    fill='currentColor'
  >
    <path d='M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z' />
  </svg>
)

export const Code = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-code'
    width={24}
    height={24}
    viewBox='0 0 24 24'
    strokeWidth={2}
    stroke='#0a0a0a'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='m7 8-4 4 4 4M17 8l4 4-4 4M14 4l-4 16' />
  </svg>
)
export const ChevronRight = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-chevron-right'
    width={24}
    height={24}
    viewBox='0 0 24 24'
    strokeWidth={2}
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='m9 6 6 6-6 6' />
  </svg>
)

export const Check = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-check'
    width={24}
    height={24}
    viewBox='0 0 24 24'
    strokeWidth={2}
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='m5 12 5 5L20 7' />
  </svg>
)

export const Analytics = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-device-desktop-analytics'
    width={44}
    height={44}
    viewBox='0 0 24 24'
    strokeWidth={2}
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M0 0h24v24H0z' stroke='none' />
    <rect x={3} y={4} width={18} height={12} rx={1} />
    <path d='M7 20h10M9 16v4M15 16v4M9 12V8M12 12v-1M15 12v-2M12 12v-1' />
  </svg>
)

export const Pencil = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-pencil'
    width={44}
    height={44}
    viewBox='0 0 24 24'
    strokeWidth={2}
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M4 20h4L18.5 9.5a1.5 1.5 0 0 0-4-4L4 16v4M13.5 6.5l4 4' />
  </svg>
)
export const Server = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-server'
    width={44}
    height={44}
    viewBox='0 0 24 24'
    strokeWidth={2}
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M0 0h24v24H0z' stroke='none' />
    <rect x={3} y={4} width={18} height={8} rx={3} />
    <rect x={3} y={12} width={18} height={8} rx={3} />
    <path d='M7 8v.01M7 16v.01' />
  </svg>
)
export const Tool = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-tool'
    width={44}
    height={44}
    viewBox='0 0 24 24'
    strokeWidth={2}
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M7 10h3V7L6.5 3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1-3 3l-6-6a6 6 0 0 1-8-8L7 10' />
  </svg>
)
export const Magnify = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-search'
    width={24}
    height={24}
    viewBox='0 0 24 24'
    strokeWidth={2}
    stroke='#0a0a0a'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M0 0h24v24H0z' stroke='none' />
    <circle cx={10} cy={10} r={7} />
    <path d='m21 21-6-6' />
  </svg>
)

export const Lock = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-lock'
    width={24}
    height={24}
    viewBox='0 0 24 24'
    strokeWidth={2}
    stroke='#0a0a0a'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M0 0h24v24H0z' stroke='none' />
    <rect x={5} y={11} width={14} height={10} rx={2} />
    <circle cx={12} cy={16} r={1} />
    <path d='M8 11V7a4 4 0 0 1 8 0v4' />
  </svg>
)

export const Rocket = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-rocket'
    width={24}
    height={24}
    viewBox='0 0 24 24'
    strokeWidth={2}
    stroke='#0a0a0a'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M0 0h24v24H0z' stroke='none' />
    <path d='M4 13a8 8 0 0 1 7 7 6 6 0 0 0 3-5 9 9 0 0 0 6-8 3 3 0 0 0-3-3 9 9 0 0 0-8 6 6 6 0 0 0-5 3' />
    <path d='M7 14a6 6 0 0 0-3 6 6 6 0 0 0 6-3' />
    <circle cx={15} cy={9} r={1} />
  </svg>
)

export const Hamburger = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='h-6 w-6'
    fill='none'
    viewBox='0 0 24 24'
    stroke='currentColor'
    width='24'
    height='24'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M4 8h16M4 16h16'
    />
  </svg>
)

export const Times = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='h-6 w-6'
    fill='none'
    viewBox='0 0 24 24'
    stroke='currentColor'
    width='24'
    height='24'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M6 18L18 6M6 6l12 12'
    />
  </svg>
)

export const Logo = () => (
  <svg
    viewBox='0 0 1066 1220'
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 2,
    }}
  >
    <path
      d='M0 270.894c-1.557-.947-2.817-.582-4.001-.594-6.239-.062-12.484-.24-18.715-.027-11.314.385-18.717-5.162-22.85-15.182-4.021-9.744-3.57-19.59 1.523-28.886 4.22-7.704 10.754-11.819 19.836-11.624 9.822.211 19.651.151 29.477.206 1.091.007 2.185.048 3.274 0 .728-.034 1.636.252 2.07-.612.505-1.003-.142-1.732-.722-2.495-1.98-2.605-4.007-5.182-5.857-7.879-10.794-15.73-16.422-33.146-16.492-52.232-.107-29.01-.484-58.029.104-87.027.454-22.373 9.352-41.772 24.372-58.249 14.914-16.359 33.476-26.487 55.318-29.726 27.789-4.12 52.933 2.746 74.709 20.662 18.307 15.061 29.307 34.486 33.201 57.901a73.639 73.639 0 0 1 1.003 12.1c-.01 47.414-.013 94.828 0 142.242.001 6.387-.558 5.539 5.402 5.516 9.514-.039 19.026-.157 28.541-.235 13.336-.111 21.789 6.328 24.995 19.203 1.989 7.993 1.372 15.817-2.345 23.225-4.279 8.531-10.992 13.158-20.85 13.152-26.358-.017-52.716.192-79.075.25-17.467.04-34.946.321-52.4-.173-10.412-.293-19.65 2.748-28.587 7.373-8.365 4.33-15.912 9.882-22.436 16.673-3.143 3.272-6.438 3.991-10.549 2.479-8.726-3.213-15.118-9.078-19.815-16.99-1.922-3.239-.844-6.215.869-9.051'
      style={{
        fillOpacity: 0,
      }}
      fill='none'
      transform='translate(299.076 174.91) scale(4.16667)'
    />
    <path
      d='M0-22.121c.001 6.079.08 12.16-.042 18.237-.044 2.201.586 3.11 2.889 2.976a77.41 77.41 0 0 1 8.413-.004c2.096.107 2.638-.745 2.623-2.72-.076-9.819-.056-19.639-.022-29.46.01-2.959-.07-5.948.299-8.871 2.168-17.146 16.795-30.783 34.034-31.873 17.445-1.101 33.978 10.671 38.259 27.434 2.298 8.996 1.306 18.052-.937 26.845-7.909 31.003-27.289 51.599-58.124 59.907-46.269 12.466-91.705-18.067-99.755-63.77-1.357-7.703-2.077-15.459.013-23.172 4.501-16.61 20.05-27.924 37.416-27.268 16.62.628 31.297 13.228 34.43 29.794 1.076 5.691.377 11.508.499 17.269C.027-25.238 0-23.68 0-22.121'
      style={{
        fill: '#ebe8d7',
      }}
      transform='translate(453.46 491.549) scale(4.16667)'
    />
    <path
      d='M0-111.038c2.156 2.83 4.224 5.732 6.483 8.478 16.402 19.936 37.359 31.606 63.063 34.599 3.459.402 3.491.366 4.214 4.185 3.045 16.087 9.561 30.568 19.962 43.257a78.328 78.328 0 0 0 10.174 10.314c3.693 3.105 7.625 5.926 11.494 8.902-.29 1.303-1.443.78-2.228.78-12.166.002-24.338.199-36.495-.137-27.25-.756-48.28-13.147-63.665-35.274C3.569-49.5-.65-64.852-.811-81.318c-.096-9.787-.018-19.577-.018-29.366l.829-.354'
      style={{
        fill: '#383552',
      }}
      transform='translate(149.943 1008.94) scale(4.16667)'
    />
    <path
      d='M0-52.002c-3.443 9.012-3.366 17.701-.065 26.254-1.763.922-3.35.584-4.875.586-37.432.051-74.865.162-112.296.035-8.175-.029-15.682 2.131-23.045 5.199-10.74 4.477-20.368 10.752-29.135 18.375-3.267 2.84-3.257 2.86-6.56.283-.613-.48-1.165-1.037-1.778-1.515-1.688-1.317-1.823-2.514-.246-4.196 4.483-4.787 9.097-9.421 14.167-13.589.964-.793 1.963-1.551 2.852-2.422 3.104-3.045 3.451-6.508 1.022-9.614-2.395-3.06-6.586-3.765-10.001-1.378-2.675 1.869-5.218 3.97-7.594 6.209-1.81 1.705-3.637 2.515-6.193 2.48-10.915-.15-21.837-.232-32.751-.035-4.834.086-7.958-1.5-9.965-7.352-1.794-5.228-1.483-10.463 1.117-15.414 1.558-2.969 3.874-4.582 7.498-4.545 16.531.176 33.064.207 49.597.203 1.981 0 3.606.569 5.223 1.596 15.986 10.164 33.576 14.533 52.394 14.514 27.763-.031 55.526-.014 83.287-.047 1.704-.002 3.551.098 5.083-.492 3.439-1.326 4.754-4.166 4.763-7.807.006-2.301-1.035-5.129.525-6.785 1.743-1.85 4.725-.662 7.156-.811 2.797-.171 5.612-.07 8.419-.068.301 0 .602.139 1.401.336'
      style={{
        fill: '#5f4e4a',
      }}
      transform='translate(948.319 1217.087) scale(4.16667)'
    />
    <path
      d='M0 15.148c-8.068-3.132-16.31-5.063-25.077-4.241-15.902 1.492-28.241 8.967-37.461 21.861-.623.871-.948 1.984-2.069 2.617-1.172-.219-1.427-1.394-1.991-2.212-13.811-20.043-37.344-27.475-60.138-18.97-.727.271-1.448.561-2.17.842-.99.227-1.289-.228-1.126-1.138 15.819-21.401 36.965-32.962 63.619-33.546 27.254-.598 49.099 10.574 65.916 31.851.666.843 1.165 1.818 1.741 2.731-.361.393-.775.462-1.244.205'
      style={{
        fill: '#316778',
      }}
      transform='translate(752.077 81.924) scale(4.16667)'
    />
    <path
      d='M0 .992c.13 3.561-.778 7.135-3.166 10.199-2.967 3.809-6.699 3.803-9.757.096-4.381-5.307-4.387-16.037-.013-21.426 3.1-3.818 6.614-3.869 9.728-.096C-.587-7.059.075-3.281 0 .992'
      style={{
        fill: '#5f4e4a',
      }}
      transform='translate(1065.773 1052.803) scale(4.16667)'
    />
    <path
      d='m0-2.578 1.125 1.139C.523-.612-.359 0-1.084-.928c-.435-.556.289-1.359 1.084-1.65'
      style={{
        fill: '#26213a',
      }}
      transform='translate(210.276 150.612) scale(4.16667)'
    />
    <path
      d='m0-.691 1.244-.205c.542.17.74.533.181.811C.844.205.267.002 0-.691'
      style={{
        fill: '#26213a',
      }}
      transform='translate(752.077 147.92) scale(4.16667)'
    />
    <path
      d='M0-8.039C0-6.016.115-3.983-.022-1.97-.415 3.764-4.305 8.067-9.995 8.567c-4.327.381-8.707.155-13.064.217-1.521.022-2.261-.593-2.234-2.206.073-4.202-.172-8.423.136-12.604.414-5.598 4.195-9.465 9.757-10.293 4.482-.668 9.012-.177 13.52-.283 1.393-.032 1.925.712 1.912 2.025-.019 2.179-.005 4.358-.005 6.538H0Z'
      style={{
        fill: '#26213a',
      }}
      transform='translate(710.498 363.132) scale(4.16667)'
    />
    <path
      d='M0 7.964c0 2.18-.044 4.361.016 6.539.043 1.594-.659 2.226-2.221 2.195-4.201-.081-8.424.148-12.599-.208-6.004-.509-10.047-4.695-10.422-10.654-.254-4.032-.012-8.094-.108-12.14-.044-1.866.793-2.447 2.514-2.419 3.891.065 7.797-.159 11.672.105C-4.21-8.147-.204-3.736-.006 3.294.038 4.85 0 6.407 0 7.964'
      style={{
        fill: '#26213a',
      }}
      transform='translate(359.597 330.22) scale(4.16667)'
    />
    <path
      d='M976.115 675.936v16.721c0 120.779.104 241.563-.1 362.343-.038 22.05-1.096 44.12-2.167 66.14-.75 15.41-1.146 15.58-15.667 13.4-116.083-17.49-201.587-77.94-255.317-182.533-13.029-25.362-24.904-51.312-29.904-79.75-2.5-14.229-2.179-14.546 10.996-16.116 116.792-13.905 208.646-69.896 277.684-164.48 3.587-4.9 5.762-11.287 14.475-15.725ZM820.273 975.053c0-8.4-.775-16.879.162-25.167 1.792-15.841 13.504-26.92 27.638-27.366 14.671-.467 28.337 11.054 29.387 27.575 1.104 17.337.75 34.941-.962 52.225-1.663 16.83-15.359 27.56-30.359 26.45-14.237-1.06-25.287-12.54-26.575-28.53-.666-8.345-.112-16.787-.112-25.187h.821Z'
      style={{
        fill: '#842851',
      }}
      transform='translate(-157.424 -130.528)'
    />
    <path
      style={{
        fillOpacity: 0,
      }}
      fill='none'
      d='M0 807.912h1065.82v445.202H0z'
      transform='matrix(1 0 0 2.7396 .001 -2213.362)'
    />
  </svg>
)
export const Logo2 = () => (
  <svg
    viewBox='0 0 1284 1416'
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 1.41421,
    }}
  >
    <path
      d='M0 .504c0 6.799 0 6.767-6.986 6.81-7.171.046-6.088-.829-6.146 6.215-.057 6.99-.013 6.991-7.237 6.991-6.931 0-6.931 0-6.932-6.831-.001-6.383-.001-6.343-6.371-6.394-7.846-.062-6.68.975-6.741-6.574-.062-7.62-.014-7.609 7.559-7.632 6.377-.019 5.511.47 5.544-5.41.043-7.695.015-7.695 7.522-7.694 6.642 0 6.602.001 6.656 7.065.049 6.537-1.03 6.023 5.887 6.04C0-6.893 0-6.904 0 .504'
      style={{
        fill: '#26213a',
      }}
      transform='translate(338.277 83.397) scale(4.16667)'
    />
    <path
      d='M0-.04c0 7.146 0 7.146-7.023 7.146-6.105.001-6.105.001-6.105 6.208-.002 6.897-.002 6.897-6.807 6.897-7.333 0-7.289 0-7.346-7.294-.052-6.707.949-5.746-6.031-5.798-7.068-.055-7.068-.013-7.068-7.094 0-7.062 0-7.024 7.083-7.074 6.92-.048 5.971.876 6.017-5.846.051-7.355.013-7.356 7.271-7.356 6.88 0 6.88 0 6.881 6.83 0 6.382 0 6.382 6.421 6.383C0-7.038 0-7.038 0-.04'
      style={{
        fill: '#26213a',
      }}
      transform='translate(1269.244 744.282) scale(4.16667)'
    />
    <path
      d='M0-40.395c7.066 0 7.066 0 7.068 7.121.001 5.983.001 5.983 5.879 5.984 7.245.001 7.245.001 7.245 7.409 0 6.719-.001 6.67-6.95 6.734-7.177.066-6.097-1.044-6.161 6.144C7.019 0 7.067 0-.084 0c-7.008 0-6.964 0-7.021-7.132-.057-6.988.939-5.875-5.821-6.049-2.295-.059-5.17 1.003-6.736-.536-1.573-1.545-.523-4.427-.548-6.715-.077-6.857-.028-6.857 6.812-6.858 6.304-.001 6.305-.001 6.305-6.491.001-6.614.001-6.614 7.093-6.614'
      style={{
        fill: '#26213a',
      }}
      transform='translate(85.543 1031.882) scale(4.16667)'
    />
    <path
      d='M0 270.894c-1.557-.947-2.817-.582-4.001-.594-6.239-.062-12.484-.24-18.715-.027-11.314.385-18.717-5.162-22.85-15.182-4.021-9.744-3.57-19.59 1.523-28.886 4.22-7.704 10.754-11.819 19.836-11.624 9.822.211 19.651.151 29.477.206 1.091.007 2.185.048 3.274 0 .728-.034 1.636.252 2.07-.612.505-1.003-.142-1.732-.722-2.495-1.98-2.605-4.007-5.182-5.857-7.879-10.794-15.73-16.422-33.146-16.492-52.232-.107-29.01-.484-58.029.104-87.027.454-22.373 9.352-41.772 24.372-58.249 14.914-16.359 33.476-26.487 55.318-29.726 27.789-4.12 52.933 2.746 74.709 20.662 18.307 15.061 29.307 34.486 33.201 57.901a73.639 73.639 0 0 1 1.003 12.1c-.01 47.414-.013 94.828 0 142.242.001 6.387-.558 5.539 5.402 5.516 9.514-.039 19.026-.157 28.541-.235 13.336-.111 21.789 6.328 24.995 19.203 1.989 7.993 1.372 15.817-2.345 23.225-4.279 8.531-10.992 13.158-20.85 13.152-26.358-.017-52.716.192-79.075.25-17.467.04-34.946.321-52.4-.173-10.412-.293-19.65 2.748-28.587 7.373-8.365 4.33-15.912 9.882-22.436 16.673-3.143 3.272-6.438 3.991-10.549 2.479-8.726-3.213-15.118-9.078-19.815-16.99-1.922-3.239-.844-6.215.869-9.051'
      style={{
        fill: '#26213a',
      }}
      transform='translate(299.076 174.91) scale(4.16667)'
    />
    <path
      d='M0-22.121c.001 6.079.08 12.16-.042 18.237-.044 2.201.586 3.11 2.889 2.976a77.41 77.41 0 0 1 8.413-.004c2.096.107 2.638-.745 2.623-2.72-.076-9.819-.056-19.639-.022-29.46.01-2.959-.07-5.948.299-8.871 2.168-17.146 16.795-30.783 34.034-31.873 17.445-1.101 33.978 10.671 38.259 27.434 2.298 8.996 1.306 18.052-.937 26.845-7.909 31.003-27.289 51.599-58.124 59.907-46.269 12.466-91.705-18.067-99.755-63.77-1.357-7.703-2.077-15.459.013-23.172 4.501-16.61 20.05-27.924 37.416-27.268 16.62.628 31.297 13.228 34.43 29.794 1.076 5.691.377 11.508.499 17.269C.027-25.238 0-23.68 0-22.121'
      style={{
        fill: '#ebe8d7',
      }}
      transform='translate(610.88 622.078) scale(4.16667)'
    />
    <path
      d='M0-111.038c2.156 2.83 4.224 5.732 6.483 8.478 16.402 19.936 37.359 31.606 63.063 34.599 3.459.402 3.491.366 4.214 4.185 3.045 16.087 9.561 30.568 19.962 43.257a78.328 78.328 0 0 0 10.174 10.314c3.693 3.105 7.625 5.926 11.494 8.902-.29 1.303-1.443.78-2.228.78-12.166.002-24.338.199-36.495-.137-27.25-.756-48.28-13.147-63.665-35.274C3.569-49.5-.65-64.852-.811-81.318c-.096-9.787-.018-19.577-.018-29.366l.829-.354'
      style={{
        fill: '#383552',
      }}
      transform='translate(307.364 1139.47) scale(4.16667)'
    />
    <path
      d='M0-110.588v4.013C0-77.588.025-48.6-.024-19.612-.033-14.32-.287-9.025-.544-3.738-.724-.041-.819 0-4.304-.524c-27.86-4.197-48.381-18.704-61.276-43.807-3.127-6.087-5.977-12.315-7.177-19.14-.6-3.415-.523-3.491 2.639-3.868 28.03-3.337 50.075-16.775 66.644-39.475.861-1.176 1.383-2.709 3.474-3.774'
      style={{
        fill: '#842851',
      }}
      transform='translate(976.115 1136.72) scale(4.16667)'
    />
    <path
      d='M0-52.002c-3.443 9.012-3.366 17.701-.065 26.254-1.763.922-3.35.584-4.875.586-37.432.051-74.865.162-112.296.035-8.175-.029-15.682 2.131-23.045 5.199-10.74 4.477-20.368 10.752-29.135 18.375-3.267 2.84-3.257 2.86-6.56.283-.613-.48-1.165-1.037-1.778-1.515-1.688-1.317-1.823-2.514-.246-4.196 4.483-4.787 9.097-9.421 14.167-13.589.964-.793 1.963-1.551 2.852-2.422 3.104-3.045 3.451-6.508 1.022-9.614-2.395-3.06-6.586-3.765-10.001-1.378-2.675 1.869-5.218 3.97-7.594 6.209-1.81 1.705-3.637 2.515-6.193 2.48-10.915-.15-21.837-.232-32.751-.035-4.834.086-7.958-1.5-9.965-7.352-1.794-5.228-1.483-10.463 1.117-15.414 1.558-2.969 3.874-4.582 7.498-4.545 16.531.176 33.064.207 49.597.203 1.981 0 3.606.569 5.223 1.596 15.986 10.164 33.576 14.533 52.394 14.514 27.763-.031 55.526-.014 83.287-.047 1.704-.002 3.551.098 5.083-.492 3.439-1.326 4.754-4.166 4.763-7.807.006-2.301-1.035-5.129.525-6.785 1.743-1.85 4.725-.662 7.156-.811 2.797-.171 5.612-.07 8.419-.068.301 0 .602.139 1.401.336'
      style={{
        fill: '#5f4e4a',
      }}
      transform='translate(1105.74 1347.616) scale(4.16667)'
    />
    <path
      d='M0 15.148c-8.068-3.132-16.31-5.063-25.077-4.241-15.902 1.492-28.241 8.967-37.461 21.861-.623.871-.948 1.984-2.069 2.617-1.172-.219-1.427-1.394-1.991-2.212-13.811-20.043-37.344-27.475-60.138-18.97-.727.271-1.448.561-2.17.842-.99.227-1.289-.228-1.126-1.138 15.819-21.401 36.965-32.962 63.619-33.546 27.254-.598 49.099 10.574 65.916 31.851.666.843 1.165 1.818 1.741 2.731-.361.393-.775.462-1.244.205'
      style={{
        fill: '#316778',
      }}
      transform='translate(909.498 212.453) scale(4.16667)'
    />
    <path
      d='M0 .992c.13 3.561-.778 7.135-3.166 10.199-2.967 3.809-6.699 3.803-9.757.096-4.381-5.307-4.387-16.037-.013-21.426 3.1-3.818 6.614-3.869 9.728-.096C-.587-7.059.075-3.281 0 .992'
      style={{
        fill: '#5f4e4a',
      }}
      transform='translate(1223.194 1183.332) scale(4.16667)'
    />
    <path
      d='m0-2.578 1.125 1.139C.523-.612-.359 0-1.084-.928c-.435-.556.289-1.359 1.084-1.65'
      style={{
        fill: '#26213a',
      }}
      transform='translate(367.697 281.14) scale(4.16667)'
    />
    <path
      d='m0-.691 1.244-.205c.542.17.74.533.181.811C.844.205.267.002 0-.691'
      style={{
        fill: '#26213a',
      }}
      transform='translate(909.498 278.448) scale(4.16667)'
    />
    <path
      d='M0-8.039C0-6.016.115-3.983-.022-1.97-.415 3.764-4.305 8.067-9.995 8.567c-4.327.381-8.707.155-13.064.217-1.521.022-2.261-.593-2.234-2.206.073-4.202-.172-8.423.136-12.604.414-5.598 4.195-9.465 9.757-10.293 4.482-.668 9.012-.177 13.52-.283 1.393-.032 1.925.712 1.912 2.025-.019 2.179-.005 4.358-.005 6.538H0Z'
      style={{
        fill: '#26213a',
      }}
      transform='translate(867.919 493.661) scale(4.16667)'
    />
    <path
      d='M0 7.964c0 2.18-.044 4.361.016 6.539.043 1.594-.659 2.226-2.221 2.195-4.201-.081-8.424.148-12.599-.208-6.004-.509-10.047-4.695-10.422-10.654-.254-4.032-.012-8.094-.108-12.14-.044-1.866.793-2.447 2.514-2.419 3.891.065 7.797-.159 11.672.105C-4.21-8.147-.204-3.736-.006 3.294.038 4.85 0 6.407 0 7.964'
      style={{
        fill: '#26213a',
      }}
      transform='translate(517.018 460.749) scale(4.16667)'
    />
    <path
      d='M0-.438c0-2.016-.186-4.051.039-6.04.43-3.802 3.241-6.461 6.633-6.568 3.521-.112 6.801 2.653 7.053 6.618.265 4.161.18 8.386-.231 12.534-.399 4.04-3.686 6.614-7.286 6.347C2.791 12.199.139 9.445-.17 5.607-.33 3.604-.197 1.578-.197-.438H0Z'
      style={{
        fill: '#26213a',
      }}
      transform='translate(820.273 976.878) scale(4.16667)'
    />
  </svg>
)
