import React from 'react'
import { Container } from '@UI'
import * as styles from './mainContent.module.scss'

const MainContent = ({ children }) => {
  return (
    <Container wrapperSm className={styles.post}>
      {children}
    </Container>
  )
}

export default MainContent
