import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Title, Container, Gradient } from '@UI'
import { Fade, Zoom } from '@animations'
import * as styles from './hero.module.scss'

const Hero = ({ image, title, date, tags }) => {
  const formattedDate = new Date(date).toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

  return (
    <div className={styles.container}>
      {/* <Fade> */}
      <Zoom className={styles.absolute}>
        <div className={styles.imageContainer}>
          <GatsbyImage
            image={getImage(image)}
            alt=''
            layout='fullWidth'
            placeholder='blurred'
            className={styles.image}
          />
        </div>
      </Zoom>

      <Gradient className={styles.gradient} />
      {/* </Fade> */}

      <Container wrapper section className={styles.textContainer}>
        <Fade delay={0.4} duration={2}>
          <Title shadow tag='h1'>
            {title}
          </Title>
        </Fade>

        <Fade delay={0.9}>
          <p className={styles.date}>Last updated: {formattedDate}</p>
          <div className={styles.tagsContainer}>
            {tags.map(({ tag }) => (
              <Link
                className={styles.tag}
                key={tag.document.id}
                to={`/tags/${tag.uid}`}
              >
                # {tag.document.data.tag_name.text}
              </Link>
            ))}
          </div>
        </Fade>
      </Container>
    </div>
  )
}

export default Hero
