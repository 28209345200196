import React from 'react'
import { Link } from 'gatsby'
import classes from 'classnames'
import { Logo as LogoSVG } from '@images/icons'
import * as styles from './logo.module.scss'

const Logo = ({ className, ...rest }) => {
  return (
    <Link to='/' className={classes(styles.link, className)} {...rest}>
      <LogoSVG />
      <span>Stellr Web</span>
    </Link>
  )
}

export default Logo
