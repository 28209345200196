import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import * as styles from './card.module.scss'

const Card = ({
  children,
  shadow,
  noHover,
  light,
  border,
  outline,
  className,
  ...rest
}) => {
  const classes = classnames(
    shadow && styles.shadow,
    noHover && styles.noHover,
    light && styles.light,
    border && styles.border,
    outline && styles.outline,
    className && className,
    styles.card
  )

  return (
    <div className={classes} {...rest}>
      <div className={styles.textContainer}>{children}</div>
    </div>
  )
}

export default Card

Card.propTypes = {
  shadow: PropTypes.bool,
  outline: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  rest: PropTypes.object,
}
