import React from 'react'
import classes from 'classnames'
import { CheckMark, BulletPoint } from '@UI'
import { motion } from 'framer-motion'

import * as styles from './checklist.module.scss'

const listVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  show: { opacity: 1, y: 0, transition: { duration: 0.5 } },
}

const Checklist = ({ data, listClass, itemClass, bullets }) => {
  return (
    <motion.ul
      variants={listVariants}
      initial='hidden'
      whileInView='show'
      viewport={{ once: true }}
      className={classes(styles.list, listClass)}
    >
      {data.map((item, i) => (
        <motion.li
          key={i}
          variants={itemVariants}
          className={classes(styles.listItem, itemClass)}
        >
          {bullets ? <BulletPoint /> : <CheckMark />}
          <span className={styles.listText}>{item}</span>
        </motion.li>
      ))}
    </motion.ul>
  )
}

export default Checklist
