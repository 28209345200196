// extracted by mini-css-extract-plugin
export var flexContainer = "flex-module--flexContainer--KyhqV";
export var alignStart = "flex-module--alignStart--aZuvq";
export var flexItem = "flex-module--flexItem--Dzbd+";
export var collapseOnMd = "flex-module--collapseOnMd--aZSvW";
export var reverseOnMd = "flex-module--reverseOnMd--q7q0D";
export var reverseOnSm = "flex-module--reverseOnSm--qz-bO";
export var basis1 = "flex-module--basis1--rlt81";
export var basis2 = "flex-module--basis2--Fc1q5";
export var basis3 = "flex-module--basis3--xc9O2";
export var basis4 = "flex-module--basis4--qYsA8";