import * as React from 'react'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

import { linkResolver } from './linkResolver'
import BlogPost from './src/templates/blogPost'

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: 'ta-prismic-test',
        linkResolver,
        componentResolver: componentResolverFromMap({
          blog_posts: BlogPost,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
)
