import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import { Logo } from '@global'
import { Button, Container } from '@UI'
import * as styles from './header.module.scss'
import Helmet from 'react-helmet'
import { useCurrentWidth } from '@hooks'
import NavButton from './navButton'

const links = [
  {
    title: 'Home',
    to: '/',
  },
  {
    title: 'Services',
    to: '/services',
  },
  {
    title: 'Case Studies',
    to: '/case-studies',
  },
  {
    title: 'Blog',
    to: '/blog',
  },
  {
    title: "Let's Chat",
    to: '/contact',
    cta: true,
  },
]

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false)

  const width = useCurrentWidth()

  useEffect(() => {
    if (width > 900) {
      setIsNavOpen(false)
    }
  }, [width])

  return (
    <header className={styles.header}>
      <Helmet
        bodyAttributes={{
          class: isNavOpen ? styles.noScroll : null,
        }}
      />
      <Container wrapper className={styles.container}>
        <Logo className={styles.logo} />

        <NavButton
          isOpen={isNavOpen}
          onClick={() => setIsNavOpen(!isNavOpen)}
        />

        <nav className={classnames(styles.nav, isNavOpen && styles.open)}>
          {links.map(({ title, to, cta }) =>
            cta ? (
              <Button to={to} key={to} cta className={styles.cta}>
                {title}
              </Button>
            ) : (
              <Link to={to} key={to} activeClassName={styles.active}>
                {title}
              </Link>
            )
          )}
        </nav>
      </Container>
    </header>
  )
}

export default Header
