exports.linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === 'blog_posts') {
    return `/blog/${doc.uid}`
  }

  // URL for a product type
  if (doc.type === 'case_study') {
    return `/case-studies/${doc.uid}`
  }

  // Backup for all other types
  return '/'
}
