import React from 'react'
import { Link } from 'gatsby'
import { Logo } from '@global'
import { Container } from '@UI'
import * as styles from './footer.module.scss'

const links = [
  {
    title: 'Home',
    to: '/',
  },
  {
    title: 'Services',
    to: '/services',
  },
  {
    title: 'Case Studies',
    to: '/case-studies',
  },
  {
    title: 'Blog',
    to: '/blog',
  },
  {
    title: 'Contact',
    to: '/contact',
  },
]

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container wrapper>
        <div className={styles.navContainer}>
          <Logo />

          <nav className={styles.nav}>
            {links.map(({ title, to }) => (
              <Link to={to} key={to}>
                {title}
              </Link>
            ))}
          </nav>
        </div>

        <div className={styles.info}>
          <p className={styles.copyright}>
            Copyright © 2018 - {new Date().getFullYear()} Stellr Web | All
            rights reserved.
          </p>
          <div>
            <a href='/sitemap/sitemap-index.xml'>Sitemap</a>
            <span> | </span>
            <a
              href='https://app.termly.io/document/privacy-policy/6cef3c7a-65b7-4af4-a357-1afafe15b367'
              target='_blank'
              rel='noreferrer'
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
