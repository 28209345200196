import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { ScrollPercentage } from 'react-scroll-percentage'
import { motion, useMotionValue, useTransform } from 'framer-motion'

const absolute = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: -1,
}

const BgZoom = ({ image, sm, children }) => {
  const x = useMotionValue(0)
  const background = useTransform(x, [0.5, 1], [1, 1.2])
  const opacity = useTransform(x, [0.55, 0.9], [0.4, 0])

  return (
    <ScrollPercentage as='div' onChange={(percentage) => x.set(percentage)}>
      <div
        style={{
          overflow: 'hidden',
          position: 'relative',
          minHeight: sm ? '50rem' : '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          paddingTop: '6rem',
        }}
      >
        <motion.div
          style={{
            scale: background,
            opacity,
            ...absolute,
          }}
        >
          <GatsbyImage style={absolute} image={getImage(image)} alt='' />
        </motion.div>

        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ delay: 0, duration: 0.7 }}
          style={{
            ...absolute,
            backgroundColor: 'black',
          }}
        />
        {children}
      </div>
    </ScrollPercentage>
  )
}

export default BgZoom
