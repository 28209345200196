import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { PrismicRichText } from '@prismicio/react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { Layout, Seo, Cta } from '@global'
import { Hero, MainContent } from '@pages/blog/post'

const BlogPost = ({ data }) => {
  console.log('data: ', data)
  const post = data.prismicBlogPosts.data

  return (
    <Layout>
      {/* <Seo
        title={`${post.blog_title.text} | Stellr Web | Cody, WY`}
        description={post.excerpt.text}
        url={`https://www.stellrweb.com/blog/${data.prismicBlogPosts.uid}`}
        imageUrl={post.cover_image.url}
        article
      /> */}

      <Helmet encodeSpecialCharacters={false}>
        <script type='application/ld+json'>{`
        { 
				"@context": "http://schema.org/", 
        "@type": "BlogPosting",
        "headline": "${post.blog_title.text}",
        "image": "${post.cover_image.url}",
        "url": "https://www.stellrweb.com/blog/${data.prismicBlogPosts.uid}",
        "datePublished": "${data.prismicBlogPosts.first_publication_date}",
        "dateCreated": "${data.prismicBlogPosts.first_publication_date}",
        "dateModified": "${data.prismicBlogPosts.last_publication_date}",
        "description": "${post.excerpt.text}",
        "publisher":{  
          "@type":"Organization",
          "@id":"https://www.stellrweb.com/",
          "name":"Stellr Web",
          "logo":{  
             "@type":"ImageObject",
             "url":"https://www.stellrweb.com/images/logo.png",
             "width":600,
             "height":662
          }
       },
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://www.stellrweb.com/",
          "breadcrumb": {
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Blog",
              "item": "https://www.stellrweb.com/blog"
            },{
              "@type": "ListItem",
              "position": 2,
              "name": "${post.blog_title.text}",
              "item": "https://www.stellrweb.com/blog/${data.prismicBlogPosts.uid}"
            }]
          }
        },
        "articleBody": "${post.blog_body.text}",
				"author": {
						"@type": "Organization",
						"name": "Stellr Web",
						"url": "https://twitter.com/StellrWeb",
						"logo":{  
							"@type":"ImageObject",
							"url":"https://www.stellrweb.com/images/logo.png",
							"width":600,
							"height":662
						}
					}
        }
      `}</script>
      </Helmet>

      <article>
        <Hero
          title={post.blog_title.text}
          image={post.cover_image}
          date={data.prismicBlogPosts.last_publication_date}
          tags={post.post_tags}
        />

        <MainContent>
          <PrismicRichText field={post.blog_body.richText} />
        </MainContent>
      </article>

      <Cta />
    </Layout>
  )
}

export default withPrismicPreview(BlogPost)

export const query = graphql`
  query PostBySlug($uid: String!) {
    prismicBlogPosts(uid: { eq: $uid }) {
      _previewable
      uid
      first_publication_date
      last_publication_date
      data {
        blog_title {
          text
        }
        cover_image {
          url
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        excerpt {
          text
        }
        post_tags {
          tag {
            uid
            document {
              ... on PrismicTags {
                id
                data {
                  tag_name {
                    text
                  }
                }
              }
            }
          }
        }
        blog_body {
          richText
          text
        }
      }
    }
  }
`
