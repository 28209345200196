import React from 'react'
import classnames from 'classnames'
import * as styles from './label.module.scss'

const Label = ({ children, className, ...rest }) => {
  return (
    <p className={classnames(styles.label, className)} {...rest}>
      {children}
    </p>
  )
}

export default Label
