import React from 'react'
import { motion } from 'framer-motion'
import { Check, ChevronRight } from '@images/icons'
import * as styles from './icon.module.scss'

export const Icon = ({ children }) => {
  return (
    <div className={styles.icon}>
      <motion.span
        className={styles.iconContainer}
        initial={{ scale: 0.9 }}
        whileInView={{ scale: [0.9, 1.1, 1] }}
        transition={{ delay: 0.2, duration: 0.7 }}
      >
        {children}
      </motion.span>
    </div>
  )
}

export const CheckMark = () => {
  return (
    <span className={styles.checkIcon}>
      <Check />
    </span>
  )
}

export const BulletPoint = () => {
  return (
    <span className={styles.chevronIcon}>
      <ChevronRight />
    </span>
  )
}
