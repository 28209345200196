import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Button, Container, Title } from '@UI'
import { Fade, Zoom } from '@animations'
import * as styles from './cta.module.scss'

const Cta = () => {
  return (
    <div className={styles.container}>
      <Zoom className={styles.image} threshold={0.6}>
        <StaticImage
          src='../../../assets/images/cta-banner.png'
          layout='fullWidth'
          className={styles.image}
          alt=''
          objectPosition={'50% 30%'}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      </Zoom>

      <Container wrapper className={styles.inner}>
        <Fade y={30} threshold={1}>
          <Title shadow className={styles.title}>
            Ready to work together?
          </Title>
        </Fade>
        <Fade>
          <Button to='/contact' cta>
            Get a Free Consultation
          </Button>
        </Fade>
      </Container>
    </div>
  )
}

export default Cta
