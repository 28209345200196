import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Title, Button } from '@UI'
import { Fade } from '@animations'
import * as styles from './imageCard.module.scss'

const ImageCard = ({ image, title, excerpt, tags, link }) => {
  return (
    <article className={styles.container}>
      <Fade className={styles.fade} threshold={0}>
        <div className={styles.imageContainer}>
          <GatsbyImage
            image={getImage(image)}
            alt=''
            layout='constrained'
            width={700}
            placeholder='blurred'
          />
        </div>

        <div className={styles.content}>
          <div className={styles.textContainer}>
            {tags && (
              <div>
                {tags.map(({ uid, label }) => (
                  <Link className={styles.tag} to={`/tags/${uid}`} key={uid}>
                    #{label}
                  </Link>
                ))}
              </div>
            )}
            <Title tag='h3' className={styles.title}>
              {title}
            </Title>
            <p>{excerpt}</p>
          </div>

          <div className={styles.linkContainer}>
            <Button to={link} cta className={styles.link}>
              Read More
            </Button>
          </div>
        </div>
      </Fade>
    </article>
  )
}

export default ImageCard
